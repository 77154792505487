import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ui-elements/ui-elements/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { ListItem, ListHeader } from '@components';
import { Button } from '@components/Button';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "listitem"
    }}>{`ListItem`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={ListItem} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<ListItem />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ListItem,
      ListHeader,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <ListItem mdxType="ListItem" />
    </Playground>
    <h2 {...{
      "id": "with-divider"
    }}>{`With Divider`}</h2>
    <Playground __position={2} __code={'<ListItem\n  divider\n  chevron\n  title=\"John Lee\"\n  user={{\n    profilePicture: \'https://picsum.photos/id/1/200\',\n  }}\n/>\n<ListItem\n  chevron\n  title=\"John Ion Vijelie\"\n  user={{\n    profilePicture: \'https://picsum.photos/id/1/200\',\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ListItem,
      ListHeader,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ListItem divider chevron title="John Lee" user={{
        profilePicture: 'https://picsum.photos/id/1/200'
      }} mdxType="ListItem" />
  <ListItem chevron title="John Ion Vijelie" user={{
        profilePicture: 'https://picsum.photos/id/1/200'
      }} mdxType="ListItem" />
    </Playground>
    <h2 {...{
      "id": "with-subtitle"
    }}>{`with Subtitle`}</h2>
    <Playground __position={3} __code={'<ListItem\n  divider\n  chevron\n  title=\"John Lee\"\n  subtitle=\"23 matches\"\n  user={{\n    profilePicture: \'https://picsum.photos/id/1/200\',\n  }}\n/>\n<ListItem\n  chevron\n  title=\"John Ion Vijelie\"\n  subtitle=\"12 matches\"\n  user={{\n    profilePicture: \'https://picsum.photos/id/1/200\',\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ListItem,
      ListHeader,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ListItem divider chevron title="John Lee" subtitle="23 matches" user={{
        profilePicture: 'https://picsum.photos/id/1/200'
      }} mdxType="ListItem" />
  <ListItem chevron title="John Ion Vijelie" subtitle="12 matches" user={{
        profilePicture: 'https://picsum.photos/id/1/200'
      }} mdxType="ListItem" />
    </Playground>
    <h2 {...{
      "id": "with-ranking"
    }}>{`With Ranking`}</h2>
    <Playground __position={4} __code={'<ListHeader columns={[\'NAME\', \'MATCHES\', \'COUNTRIES\']} />\n<ListItem\n  divider\n  chevron\n  position={1}\n  title=\"John Lee\"\n  standingValues={[35, 12]}\n  user={{\n    profilePicture: \'https://picsum.photos/id/1/200\',\n  }}\n/>\n<ListItem\n  divider\n  chevron\n  position={2}\n  standingValues={[30, 8]}\n  title=\"Patrick Nevil\"\n  user={{\n    profilePicture: \'https://picsum.photos/id/2/200\',\n  }}\n/>\n<ListItem\n  divider\n  chevron\n  position={3}\n  standingValues={[18, 3]}\n  title=\"Jan Claude\"\n  user={{\n    profilePicture: \'https://picsum.photos/id/3/200\',\n  }}\n/>\n<ListItem\n  position={4}\n  standingValues={[10, 2]}\n  chevron\n  divider\n  highlight\n  title=\"Constantin Predescu\"\n  user={{\n    profilePicture: \'https://picsum.photos/id/4/200\',\n  }}\n/>\n<ListItem\n  position={1443}\n  standingValues={[1, 1]}\n  chevron\n  title=\"Popescu Ion\"\n  user={{\n    profilePicture: \'https://picsum.photos/id/4/200\',\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ListItem,
      ListHeader,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ListHeader columns={['NAME', 'MATCHES', 'COUNTRIES']} mdxType="ListHeader" />
  <ListItem divider chevron position={1} title="John Lee" standingValues={[35, 12]} user={{
        profilePicture: 'https://picsum.photos/id/1/200'
      }} mdxType="ListItem" />
  <ListItem divider chevron position={2} standingValues={[30, 8]} title="Patrick Nevil" user={{
        profilePicture: 'https://picsum.photos/id/2/200'
      }} mdxType="ListItem" />
  <ListItem divider chevron position={3} standingValues={[18, 3]} title="Jan Claude" user={{
        profilePicture: 'https://picsum.photos/id/3/200'
      }} mdxType="ListItem" />
  <ListItem position={4} standingValues={[10, 2]} chevron divider highlight title="Constantin Predescu" user={{
        profilePicture: 'https://picsum.photos/id/4/200'
      }} mdxType="ListItem" />
  <ListItem position={1443} standingValues={[1, 1]} chevron title="Popescu Ion" user={{
        profilePicture: 'https://picsum.photos/id/4/200'
      }} mdxType="ListItem" />
    </Playground>
    <h2 {...{
      "id": "with-action"
    }}>{`with Action`}</h2>
    <Playground __position={5} __code={'<ListItem\n  title=\"John Lee\"\n  subtitle=\"43 matches\"\n  user={{\n    profilePicture: \'https://picsum.photos/id/1/200\',\n  }}\n  onPress={() => {\n    console.log(\'press row\')\n  }}\n  actionButton={\n    <Button\n      title=\"Follow\"\n      size=\"medium\"\n      rounded\n      onPress={() => {\n        console.log(\'press button\')\n      }}\n    />\n  }\n/>\n<ListItem\n  title=\"John Lee\"\n  subtitle=\"43 matches\"\n  user={{\n    profilePicture: \'https://picsum.photos/id/1/200\',\n  }}\n  onPress={() => {\n    console.log(\'press row\')\n  }}\n  actionButton={\n    <Button\n      title=\"Following\"\n      size=\"medium\"\n      type=\"outline\"\n      rounded\n      onPress={() => {\n        console.log(\'press button\')\n      }}\n    />\n  }\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ListItem,
      ListHeader,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ListItem title="John Lee" subtitle="43 matches" user={{
        profilePicture: 'https://picsum.photos/id/1/200'
      }} onPress={() => {
        console.log('press row');
      }} actionButton={<Button title="Follow" size="medium" rounded onPress={() => {
        console.log('press button');
      }} mdxType="Button" />} mdxType="ListItem" />
  <ListItem title="John Lee" subtitle="43 matches" user={{
        profilePicture: 'https://picsum.photos/id/1/200'
      }} onPress={() => {
        console.log('press row');
      }} actionButton={<Button title="Following" size="medium" type="outline" rounded onPress={() => {
        console.log('press button');
      }} mdxType="Button" />} mdxType="ListItem" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      